<template>
  <div class="content">
    <img src="../../assets/img/all_service.png" alt="" />
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="less" scoped>
.content {
  img {
    width: 100%;
    vertical-align: bottom;
  }
}
</style>
